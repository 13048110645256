.DateRangePicker {
  /* margin-top: 6px; */
  width: 100%;
  z-index: 100;
}

.DateRangePickerInput {
  border-radius: 4px;
  width: 100%;
  border: 1px solid #bdbdbd;
}

.DateInput {
  width: 45%;
}
.DateInput_input {
  padding: 14px 11px 14px;
  font-size: 1.1875em;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 18px;
  vertical-align: middle;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
